import React from "react";
import moment from "moment";
import * as Yup from "yup";
import { Formik } from "formik";
import { Box, Button, Stack } from "@mui/material";

import {
  FormInput,
  FormTextArea,
  FormInputDateTime,
  SubmitLoadingButton,
} from "../../../../../components/Forms";

// tip race validationSchema
const validationSchema = Yup.object().shape({
  tipRaceTitle: Yup.string()
    .required()
    .min(2)
    .max(50)
    .label("Race Title")
    .trim(),
  tipRaceNumber: Yup.number().required().min(1).label("Race Number"),
  tipRaceGoing: Yup.string()
    .required()
    .min(1)
    .max(50)
    .label("Race Going")
    .trim(),
  tipRaceDetail: Yup.string()
    .required()
    .min(1)
    .max(1000)
    .label("Race Tips")
    .trim(),
  tipRaceDate: Yup.date().required().label("Race date & time"),
});

// date time picker support this format - yyyy-MM-ddThh:mm
const initialValue = {
  tipRaceTitle: "",
  tipRaceNumber: 1,
  tipRaceGoing: "",
  tipRaceDetail: "",
  tipRaceDate: moment().format("YYYY-MM-DDTHH:mm"),
};

function TipRaceForm({
  onSubmit,
  initialValues = initialValue,
  state,
  onCancel,
}) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ setFieldValue }) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
          }}
        >
          <FormInput label="Race Title" name="tipRaceTitle" type="text" />
          <FormInput
            label="Race Number"
            name="tipRaceNumber"
            type="number"
            min="1"
          />
          <FormInput label="Race Going" name="tipRaceGoing" type="text" />
          <FormTextArea label="Race Tips" name="tipRaceDetail" type="text" />
          <FormInputDateTime
            label="Race Date & Time"
            name="tipRaceDate"
            onChange={(value) => {
              setFieldValue("tipRaceDate", value);
            }}
          />
          <Stack spacing={2} direction="row">
            <Button fullWidth variant="outlined" onClick={onCancel}>
              CANCEL
            </Button>
            <SubmitLoadingButton
              buttonText="submit"
              type="submit"
              submitButton={true}
              loading={state.loading}
            />
          </Stack>
        </Box>
      )}
    </Formik>
  );
}

export default TipRaceForm;
