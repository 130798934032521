import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Outfit, sans-serif",
    htmlFontSize: 16,
    h5: {
      fontSize: "1.75rem",
    },
    subtitle1: {
      fontSize: "1.07rem",
    },
    subtitle2: {
      fontSize: "0.7rem",
    },
    body1: {
      fontSize: "0.95rem",
    },
  },
  palette: {
    primary: {
      main: "#2087f5",
    },
    white: { main: "#ffffff" },
    black: { main: "#000000" },
    smallTextColor: { main: "#5F646C" },
    textColor: { main: "#252729" },
    grayWhite: {
      main: "#A3A7AE",
    },
  },
});

export default theme;
