import React from 'react'
import { useFormikContext } from "formik";
import styles from "./formButton.module.css";

function SubmitButton({ buttonText, size = 2.2, isBackground = true, ...otherProps }) {
    const { isValid, handleSubmit } = useFormikContext();

    return (
        <button
            style={{ fontSize: `${size}rem` }}
            type="submit"
            className={`${styles.button} ${isBackground ? styles.withBackground : styles.noBackground}`}
            onClick={(e) => handleSubmit(e)} {...otherProps}
            disabled={!isValid}
        >
            {buttonText}
        </button>
    )
}

export default SubmitButton
