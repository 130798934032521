import moment from "moment";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Stack, Typography, Box, Button } from "@mui/material";
// components
import { Loader, ErrorMessage } from "../../../../components";

// services
import { getSingleArticle } from "../../../../services/articleService";
import http from "../../../../services/httpService";
import { ShowHtmlContent } from "./component";

function ArticleDetail({ onPublicView }) {
  const navigate = useNavigate();

  const params = useParams();
  const articleId = params.id;

  const onError = (ex) => {
    if (ex.response && ex.response.status >= 400 && ex.response.status <= 409) {
      navigate("/not-found");
    }
  };

  const { isError, isLoading, data, error } = useQuery(
    ["article-detail", articleId],
    () => getSingleArticle(articleId),
    { refetchOnWindowFocus: true, onError }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorMessage message={error.response.data.message} />;
  }

  const article = data?.data?.data["article"];

  return (
    <Stack spacing={5} padding={3}>
      <Stack
        style={{
          display: "flex",
          // justifyContent: "center",
          // alignSelf: "end",
          margin: 0,
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          size="small"
          onClick={() => onPublicView(articleId)}
          style={{ backgroundColor: "#2087f5" }}
        >
          Public View
        </Button>
      </Stack>

      <img
        src={`${http.baseUrl}images/${article?.articleImage}`}
        width="100%"
        alt={article.articleTitle}
      />
      <Stack
        padding={3}
        margin={0}
        backgroundColor="white.main"
        style={{
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        }}
      >
        <Typography variant="h5" fontWeight="600" color="textColor.main">
          {article.articleTitle}
        </Typography>
        <Typography
          variant="body1"
          color="smallTextColor.main"
          style={{ margin: "10px 0px" }}
          fontWeight="300"
        >
          {moment(article.articleDate).format("DD MMMM YYYY")}
        </Typography>
        <Typography
          variant="subtitle1"
          color="textColor.main"
          style={{ margin: "10px 0px" }}
          fontWeight="300"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <ShowHtmlContent content={article.articleData} />
          </Box>
        </Typography>
      </Stack>
    </Stack>
  );
}

export default ArticleDetail;
