import React from "react";
import { useField } from "formik";
import { TextField } from "@mui/material";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function FormInput({
  label,
  signal,
  showError = true,
  type = "text",
  ...otherProps
}) {
  const [field, meta] = useField(otherProps);

  let inputValue =
    type === "text" &&
    typeof field.value === "string" &&
    field.value.length >= 1
      ? capitalizeFirstLetter(field.value)
      : field.value;

  const handleChange = (event) => {
    let textData = event.target.value;

    if (
      type === "text" &&
      typeof textData === "string" &&
      textData.length >= 1
    ) {
      textData = capitalizeFirstLetter(textData);
      event.target.value = textData;
    }

    field.onChange(event);
  };

  return (
    <TextField
      fullWidth
      // autoComplete="username"
      size="small"
      type={type}
      label={label}
      {...field}
      onChange={handleChange}
      value={inputValue}
      error={Boolean(meta.touched && meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
}

export default FormInput;
