import React from "react";
import { Typography } from "@mui/material";

function HeaderTitleSubtitle({ title, subtitle, note }) {
  return (
    <>
      {title && <Typography variant="h5">{title}</Typography>}
      {subtitle && <Typography variant="body1">{subtitle}</Typography>}
      {note && (
        <Typography variant="body1">
          <span className="w-600">Note : </span>
          {note}
        </Typography>
      )}
    </>
  );
}

export default HeaderTitleSubtitle;
