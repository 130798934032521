import moment from "moment-timezone";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
// components
import { RaceForm } from "./component";
import { Loader, ErrorMessage } from "../../../../components";
// hooks
import useResponse from "../../../../hooks/useResponse";
// services
import {
  getSingleRace,
  updateRace,
} from "../../../../services/raceEventService";
import * as toast from "../../../../Toast";
// utils
import {
  convertDateTimeTogivenTimezone,
  getTimezone,
} from "../../utils/timezone";
import trimObjectValues from "../../../../utils/trimObject";

function EditRace({ onBack }) {
  const navigate = useNavigate();
  const [state, dispatch] = useResponse();
  const params = useParams();
  const raceId = params.id;

  const onError = (ex) => {
    if (ex.response && ex.response.status >= 400 && ex.response.status <= 409) {
      navigate("/not-found");
    }
  };

  const { isError, isLoading, data, error } = useQuery(
    ["race-edit", raceId],
    () => getSingleRace(raceId),
    { refetchOnWindowFocus: true, onError, cacheTime: 100 }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorMessage message={error.response.data.message} />;
  }

  const race = data.data.data["race"];

  const handleSubmit = async (raceData) => {
    let raceDate = moment(raceData.raceDate).tz(
      getTimezone(raceData.timezone),
      true
    );
    raceData.raceDate = raceDate.toISOString();

    dispatch.sendingRequst();
    try {
      const trimRaceData = trimObjectValues(raceData);
      const response = await updateRace(raceId, trimRaceData);
      const data = await response.data;
      toast.success(data.message);
      dispatch.successResponse(data.message);

      // go back to listing page
      onBack();
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        dispatch.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  return (
    <RaceForm
      title="Edit Race"
      initialValues={{
        raceName: race.raceName,
        raceDate: moment(
          convertDateTimeTogivenTimezone(race.raceDate, race.timezone)
        ).format("YYYY-MM-DDTHH:mm"),
        locationId: race.locationId,
        locationAddress: race.locationAddress,
        timezone: race.timezone,
      }}
      onSubmit={handleSubmit}
      state={state}
      onCancel={onBack}
    />
  );
}

export default EditRace;
