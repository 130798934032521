import React, { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Stack, Button, Typography, Box } from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
// components
import { Loader, ErrorMessage } from "../../../../components";
import { TipLocationFormModel, TipLocationItem } from "./component";
// hooks
import useResponse from "../../../../hooks/useResponse";
import useVisible from "../../../../hooks/useVisible";
// services
import {
  getAllTipLocationByTipId,
  createTipLocation,
  updateTipLocation,
  deleteTipLocation,
} from "../../../../services/tipLocationService";
import * as toast from "../../../../Toast";

function getLocationIdList(tipLocationList = []) {
  return tipLocationList.map((tipLocation) => {
    return tipLocation.locationId;
  });
}

function TipDetail({ onDetail }) {
  const navigate = useNavigate();
  const params = useParams();
  const [tipLocationList, setTipLocationList] = useState([]);
  const [isAddShow, toggleAddModal] = useVisible(false);
  const [state, dispatch] = useResponse();
  const tipId = params.id;

  const onError = (ex) => {
    if (ex.response && ex.response.status >= 400 && ex.response.status <= 409) {
      navigate("/not-found");
    }
  };

  const { isError, isLoading, data, error } = useQuery(
    ["tip-detail", tipId],
    () => getAllTipLocationByTipId(tipId),
    { refetchOnWindowFocus: true, onError }
  );

  const tipLocations = data?.data?.data["tipLocations"];

  useEffect(() => {
    if (tipLocations) {
      setTipLocationList(tipLocations);
    }
  }, [tipLocations]);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorMessage message={error.response.data.message} />;
  }

  const handleSubmit = async ({ locationId }) => {
    dispatch.sendingRequst();
    try {
      const response = await createTipLocation({ locationId, tipId });
      const data = await response.data;
      const tipLocation = data.data.tipLocation;
      setTipLocationList((state) => [...state, tipLocation]);
      toggleAddModal();
      toast.success(data.message);
      dispatch.successResponse(data.message);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        dispatch.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  const handleUpdateTipLocation = async (
    { id: tipLocationId, locationId },
    // tipLocationData,
    callBack
  ) => {
    dispatch.sendingRequst();

    try {
      const res = await updateTipLocation(tipLocationId, {
        locationId,
        tipId,
      });
      const data = await res.data;
      const tipLocation = data.data.tipLocation;

      // updating tip location in state based on index of tipLocation
      const index = findIndexOfTipLocation(tipLocationId);
      if (index !== -1) {
        updateTipLocationOfGivenIndex(index, tipLocation);
      }

      callBack();
      dispatch.successResponse(data.message);
      toast.success(data.message);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        dispatch.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  const updateTipLocationOfGivenIndex = (index, tipLocation) => {
    tipLocationList[index] = tipLocation;
    setTipLocationList(tipLocationList);
  };

  const findIndexOfTipLocation = (tipLocationId) => {
    return tipLocationList.findIndex(
      (tipLocation) => tipLocation.id === tipLocationId
    );
  };

  function removeDeleteTipLocation(tipLocationId) {
    const tipLocationNewList = tipLocationList.filter(
      (tipLocation) => tipLocation.id !== tipLocationId
    );

    setTipLocationList(tipLocationNewList);
  }

  return (
    <>
      <TipLocationFormModel
        title="Add Location"
        showModal={isAddShow}
        toggleModal={toggleAddModal}
        onSubmit={handleSubmit}
        state={state}
        locationIdList={getLocationIdList(tipLocationList)}
      />
      <Stack spacing={5} padding={3}>
        <Stack
          style={{
            display: "flex",
            // justifyContent: "center",
            // alignSelf: "end",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="contained"
            size="small"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate(-1)}
            style={{ backgroundColor: "#2087f5" }}
          >
            Back
          </Button>
          <Button
            variant="outlined"
            size="large"
            startIcon={<AddIcon />}
            onClick={toggleAddModal}
          >
            Add Location
          </Button>
        </Stack>
        <Stack>
          <Typography variant="h5">Tip Locations</Typography>
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              gap: 2,
            }}
          >
            {tipLocationList.map((tipLocation) => (
              <TipLocationItem
                tipLocation={tipLocation}
                // tipId={tipId}
                key={tipLocation.id}
                updateRecord={handleUpdateTipLocation}
                deleteRecord={deleteTipLocation}
                removeLocation={removeDeleteTipLocation}
                onLocationClick={onDetail}
                locationIdList={getLocationIdList(tipLocationList)}
                parentState={state}
              />
            ))}
          </Box>
        </Stack>
      </Stack>
    </>
  );
}

export default TipDetail;
