import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import { Layout } from "../../components";

// tips pages
import TipListing from "./TipListing";
import TipDetail from "./TipDetail";
import TipLocation from "./TipLocation";

function Tips() {
  const navigate = useNavigate();

  const handleNavigateToListing = () => {
    navigate("/dashboard/tips/");
  };

  const handleTipDetailClick = (tipId) => {
    navigate(`/dashboard/tips/tip-detail/${tipId}`);
  };

  const handleTipLocationDetailClick = (tipLocationId) => {
    navigate(`/dashboard/tips/tip-location/${tipLocationId}`);
  };

  return (
    <Layout>
      <Routes>
        <Route
          path="/"
          element={<TipListing onDetail={handleTipDetailClick} />}
        />
        <Route
          path="/tip-detail/:id"
          element={
            <TipDetail
              onBack={handleNavigateToListing}
              onDetail={handleTipLocationDetailClick}
            />
          }
        />
        <Route
          path="/tip-location/:id"
          element={<TipLocation onBack={handleNavigateToListing} />}
        />
      </Routes>
    </Layout>
  );
}

export default Tips;
