import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Box, Stack, Typography } from "@mui/material";

// components
import {
  SubmitLoadingButton,
  TinyEditor,
} from "../../../../../components/Forms";

// initialValues
const initialValue = {
  privacyPolicy: "",
  privacyPolicyText: "",
  termsConditions: "",
  termsConditionsText: "",
};

function ConfigForm({ onSubmit, state, initialValues = initialValue }) {
  // configuration validation
  const validationSchema = Yup.object().shape({
    privacyPolicy: Yup.string().min(5).label("Privacy Policy").trim(),
    privacyPolicyText: Yup.string()
      .required("Privacy Policy is required")
      .min(5, "Privacy Policy must be at least 5 characters")
      .max(50000, "Privacy Policy must be at most 50000 characters")
      .label("Privacy Policy"),
    termsConditions: Yup.string().min(5).label("Terms and Conditions").trim(),
    termsConditionsText: Yup.string()
      .required("Terms and Conditions is required")
      .min(5, "Terms and Conditions must be at least 5 characters")
      .max(50000, "Terms and Conditions must be at most 50000 characters")
      .label("Terms and Conditions"),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ setFieldValue, setFieldTouched, setFieldError }) => (
        <Box
          sx={{
            margin: "50px",
            // width: "400px",
            display: "flex",
            flexDirection: "column",
            gap: 1.8,
          }}
        >
          <Typography variant="subtitle1">Privacy And Policy</Typography>
          <TinyEditor
            label="Privacy Policy"
            name="privacyPolicy"
            dataTextName="privacyPolicyText"
            type="text"
            onChangeText={(value) => {
              setFieldValue("privacyPolicyText", value);
            }}
            onBlur={() => {
              setFieldTouched("privacyPolicyText", true);
            }}
            onChange={(value) => {
              setFieldTouched("privacyPolicyText", true);
              setFieldTouched("privacyPolicy", true);
              setFieldValue("privacyPolicy", value);
            }}
            onError={(message) => {
              setFieldError("privacyPolicy", message);
            }}
          />
          <Typography variant="subtitle1">Terms and Conditions</Typography>
          <TinyEditor
            label="Terms and Conditions"
            name="termsConditions"
            dataTextName="termsConditionsText"
            type="text"
            onChangeText={(value) => {
              setFieldValue("termsConditionsText", value);
            }}
            onBlur={() => {
              setFieldTouched("termsConditionsText", true);
            }}
            onChange={(value) => {
              setFieldTouched("termsConditionsText", true);
              setFieldTouched("termsConditions", true);
              setFieldValue("termsConditions", value);
            }}
            onError={(message) => {
              setFieldError("termsConditions", message);
            }}
          />
          <Stack spacing={2} direction="row">
            <SubmitLoadingButton
              buttonText="submit"
              type="submit"
              submitButton={true}
              loading={state.loading}
            />
          </Stack>
          {/* <SuccessAndErrorMessage error={state.error} success={state.message} /> */}
        </Box>
      )}
    </Formik>
  );
}

export default ConfigForm;
