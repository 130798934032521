import React from "react";
import moment from "moment";
import { TipForm } from ".";
import { ModelWrapper } from "../../../components";

const initialValue = {
  tipTitle: "",
  isPublished: false,
  publishedAt: moment().format("YYYY-MM-DDTHH:mm"),
};

function TipFormModel({
  showModal,
  toggleModal,
  onSubmit,
  title,
  subtitle,
  state,
  initialValues = initialValue,
  isCreated = false,
}) {
  if (!showModal) return null;
  return (
    <ModelWrapper
      toggleModal={toggleModal}
      title={title}
      subtitle={subtitle}
      error={state.error}
    >
      <TipForm
        onSubmit={onSubmit}
        state={state}
        onCancel={toggleModal}
        initialValues={initialValues}
        isCreated={isCreated}
      />
    </ModelWrapper>
  );
}

export default TipFormModel;
