import React, { useState } from "react";
import { Button, Stack } from "@mui/material";
import { ErrorMessage, useField } from "formik";
import SuccessAndErrorMessage from "../SuccessAndErrorMessage";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

// services
import http from "../../services/httpService";

function FormImageInput({
  name,
  imageValues,
  checkResolution,
  onChangeSelectedImage,
  onError,
  onCheckRes,
}) {
  const [field] = useField(name);
  const [preview, setPreview] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleFileChange = (event) => {
    if (!event.target.files) {
      return;
    }

    const selectedFile = event.target.files[0];
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    onChangeSelectedImage(event);

    // validate resolutions
    const image = new Image();
    image.src = objectUrl;

    image.onload = (img) => {
      // const width = image.width;
      // const height = image.height;

      // if (
      //   (width < 600 && height < 600) ||
      //   (width >= 600 && height < 600) ||
      //   (width < 600 && height >= 600)
      // ) {
      //   onError("Image size be more than 600x600 pixels.");
      //   setErrorMessage("Image size be more than 600x600 pixels.");
      //   onCheckRes(true);
      // } else {
      onCheckRes(false);
      setErrorMessage("");
      // }
    };

    image.onerror = (error) => {
      onError("Input file contains unsupported image format.");
      setErrorMessage("Input file contains unsupported image format.");
      onCheckRes(true);
    };
  };

  return (
    <>
      <Stack>
        <Button
          component="label"
          variant="outlined"
          startIcon={<FileUploadOutlinedIcon />}
          sx={{ margin: 0 }}
        >
          Upload Article Image {field.value && `- ${field.value.name}`}
          <input
            type="file"
            accept="image/*, image/jpeg"
            hidden
            name={name}
            onBlur={field.onBlur}
            onChange={handleFileChange}
          />
        </Button>
        <p className="note">
          <span className="w-700">Recommended Resolution : </span>
          450 * 300 px (Less than 1 MB in size)
        </p>
      </Stack>
      {(imageValues || field.value) && (
        <img
          src={field.value ? preview : `${http.baseUrl}images/${imageValues}`}
          alt="article pic"
          width={100}
        />
      )}
      <ErrorMessage name={name}>
        {(message) => <SuccessAndErrorMessage error={message} />}
      </ErrorMessage>
      {checkResolution && field.value && (
        <SuccessAndErrorMessage error={errorMessage} />
      )}
    </>
  );
}

export default FormImageInput;
