import React from "react";
import moment from "moment";
import * as Yup from "yup";
import { Formik } from "formik";
import { Box, Button, Stack } from "@mui/material";

import {
  FormInput,
  FormInputDate,
  FormSwitch,
  SubmitLoadingButton,
} from "../../../../../components/Forms";

// race event validationSchema
const validationSchema = Yup.object().shape({
  tipTitle: Yup.string().required().min(2).max(50).label("Tip Title").trim(),
  isPublished: Yup.boolean().required().label("Publish Status"),
  publishedAt: Yup.date().required().label("Publish date"),
});

const initialValue = {
  tipTitle: "",
  isPublished: false,
  publishedAt: moment().format("YYYY-MM-DDTHH:mm"),
};

function TipForm({
  onSubmit,
  state,
  onCancel,
  initialValues = initialValue,
  isCreated = false,
}) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ setFieldValue, setFieldTouched, setFieldError }) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            gap: 2,
          }}
        >
          <FormInput label="Tip Title" name="tipTitle" type="text" />
          <FormInputDate
            label="Publish Date"
            name="publishedAt"
            onChange={(value) => {
              setFieldValue("publishedAt", value);
            }}
          />
          {isCreated === false && (
            <FormSwitch label="Published Status" name="isPublished" />
          )}
          <Stack spacing={2} direction="row">
            <Button fullWidth variant="outlined" onClick={onCancel}>
              CANCEL
            </Button>
            <SubmitLoadingButton
              buttonText="submit"
              type="submit"
              submitButton={true}
              loading={state.loading}
            />
          </Stack>
        </Box>
      )}
    </Formik>
  );
}

export default TipForm;
