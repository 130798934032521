import React from "react";
import { useQuery } from "react-query";
// component
import { ConfigForm } from "./components";
import { Loader, ErrorMessage } from "../../../../components";
// hooks
import useResponse from "../../../../hooks/useResponse";

// services
import { getConfig, updateConfig } from "../../../../services/configService";

import * as toast from "../../../../Toast";

function Config() {
  const [state, dispatch] = useResponse();

  const { isError, isLoading, data, error } = useQuery(
    ["config-form"],
    () => getConfig(),
    { refetchOnWindowFocus: true, cacheTime: 100 }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorMessage message={error.response.data.message} />;
  }

  const {
    id: configId,
    privacyPolicyDetail,
    termsConditionsDetail,
  } = data.data.data["configValue"];

  const handleSubmit = async (configData) => {
    const {
      privacyPolicy: privacyPolicyDetail,
      termsConditions: termsConditionsDetail,
    } = configData;

    const updatedConfigData = {
      privacyPolicyDetail: encodeURI(privacyPolicyDetail),
      termsConditionsDetail: encodeURI(termsConditionsDetail),
    };
    dispatch.sendingRequst();
    try {
      const response = await updateConfig(configId, updatedConfigData);
      const data = await response.data;

      toast.success(data.message);
      dispatch.successResponse(data.message);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        dispatch.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  return (
    <ConfigForm
      onSubmit={handleSubmit}
      state={state}
      initialValues={{
        privacyPolicy: decodeURI(privacyPolicyDetail),
        privacyPolicyText: "",
        termsConditions: decodeURI(termsConditionsDetail),
        termsConditionsText: "",
      }}
    />
  );
}

export default Config;
