import React from "react";
import { ErrorMessage, SuccessMessage } from ".";

function SuccessAndErrorMessage({ error, success }) {
  if (error) {
    return <ErrorMessage message={error} />;
  }

  if (success) {
    return <SuccessMessage message={success} />;
  }

  return null;
}

export default SuccessAndErrorMessage;
