import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import { Layout } from "../../components";

// race event pages
import RaceListing from "./RaceListing";
import AddRace from "./AddRace";
import EditRace from "./EditRace";

function RaceEvent() {
  const navigate = useNavigate();

  const handleAddClick = () => {
    navigate("/dashboard/races/add-race");
  };

  const handleNavigateToListing = () => {
    // navigate("/dashboard/races/");
    navigate(-1);
  };

  const handleEditClick = (raceId) => {
    navigate(`/dashboard/races/edit-race/${raceId}`);
  };

  return (
    <Layout>
      <Routes>
        <Route
          path="/"
          element={
            <RaceListing
              onAdd={handleAddClick}
              onBack={handleNavigateToListing}
              onEdit={handleEditClick}
            />
          }
        />
        <Route
          path="/add-race"
          element={<AddRace onBack={handleNavigateToListing} />}
        />
        <Route
          path="/edit-race/:id"
          element={<EditRace onBack={handleNavigateToListing} />}
        />
      </Routes>
    </Layout>
  );
}

export default RaceEvent;
