import React from "react";
import moment from "moment";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
// components
import { ArticleForm } from "./component";
import { Loader, ErrorMessage } from "../../../../components";
// hooks
import useResponse from "../../../../hooks/useResponse";
// services
import {
  updateArticle,
  getSingleArticle,
} from "../../../../services/articleService";
import { imageUploadAPI } from "../../../../services/uploadService";
import * as toast from "../../../../Toast";

// utils
import trimObjectValues from "../../../../utils/trimObject";

function EditArticle({ onBack }) {
  const navigate = useNavigate();
  const [state, dispatch] = useResponse();
  const params = useParams();
  const articleId = params.id;

  const onError = (ex) => {
    if (ex.response && ex.response.status >= 400 && ex.response.status <= 409) {
      navigate("/not-found");
    }
  };

  const { isError, isLoading, data, error } = useQuery(
    ["article-edit", articleId],
    () => getSingleArticle(articleId),
    { refetchOnWindowFocus: true, onError, cacheTime: 100 }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorMessage message={error.response.data.message} />;
  }

  const article = data.data.data["article"];

  const handleSubmit = async (articleFormData) => {
    let articleDate = new Date(articleFormData.articleDate);
    articleFormData.articleDate = articleDate.toISOString();

    const { articleTitle, articleData, selectedImage, status, articleImage } =
      articleFormData;

    dispatch.sendingRequst();
    try {
      const updatedArticleData = {
        articleTitle,
        articleData: encodeURI(articleData),
        articleDate,
        status,
        articleImage,
        articleThumbnail: article.articleThumbnail,
      };

      if (selectedImage) {
        const imageUploadRes = await imageUploadAPI(selectedImage);
        const imageRes = await imageUploadRes.data;
        updatedArticleData.articleImage = imageRes.file.filename;
        updatedArticleData.articleThumbnail = imageRes.thumbnail;
      }

      const trimArticleData = trimObjectValues(updatedArticleData);
      const response = await updateArticle(articleId, trimArticleData);
      const data = await response.data;

      toast.success(data.message);
      dispatch.successResponse(data.message);

      // go back to listing page
      onBack();
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        dispatch.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  return (
    <ArticleForm
      title="Edit Article"
      onSubmit={handleSubmit}
      isEdit={true}
      initialValues={{
        articleTitle: article.articleTitle,
        articleData: decodeURI(article.articleData),
        articleDataText: "",
        articleImage: article.articleImage,
        selectedImage: "",
        articleDate: moment(article.articleDate).format("YYYY-MM-DDTHH:mm"),
        status: article.status,
        checkResolution: false,
      }}
      state={state}
      onCancel={onBack}
    />
  );
}

export default EditArticle;
