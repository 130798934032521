import React from "react";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ModelWrapper } from ".";

function ConfirmationModal({
  showModal,
  toggleModal,
  onConfirm,
  title,
  subtitle,
  note,
  error,
  loading = false,
  ConfirmButtonText = "CONFIRM",
}) {
  if (!showModal) return null;

  return (
    <ModelWrapper
      toggleModal={toggleModal}
      title={title}
      subtitle={subtitle}
      note={note}
      error={error}
    >
      <Stack spacing={2} direction="row">
        <Button fullWidth variant="outlined" onClick={toggleModal}>
          CANCEL
        </Button>
        <LoadingButton
          fullWidth
          size="large"
          variant="contained"
          onClick={onConfirm}
          loading={loading}
          disabled={loading}
        >
          {loading ? "loading..." : ConfirmButtonText}
        </LoadingButton>
      </Stack>
    </ModelWrapper>
  );
}

export default ConfirmationModal;
