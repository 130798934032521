import moment from "moment";
import React, { useCallback, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Stack, Button } from "@mui/material";
// components
import { ListingTable } from "./component";
import { TipFormModel } from "./component";

// hooks
import useVisible from "../../../../hooks/useVisible";
import useResponse from "../../../../hooks/useResponse";

// services
import {
  createTip,
  deleteTip,
  getAllTipWithPagination,
  updateTip,
} from "../../../../services/tipsService";
import * as toast from "../../../../Toast";
// utils
import trimObjectValues from "../../../../utils/trimObject";

function TipListing({ onDetail }) {
  const [isAddShow, toggleAddModal] = useVisible(false);
  const [state, dispatch] = useResponse();
  const [refreshState, setRefreshState] = useState(false);

  const handleSubmit = async (tipData) => {
    dispatch.sendingRequst();
    try {
      const trimTipData = trimObjectValues(tipData);

      const publishedAt = moment(trimTipData.publishedAt).format("YYYY-MM-DD");
      trimTipData.publishedAt = new Date(publishedAt);

      const response = await createTip(trimTipData);
      const data = await response.data;
      toggleAddModal();
      toast.success(data.message);
      dispatch.successResponse(data.message);
      // set refresh state to true, to refresh listing
      setRefreshState(true);
      // set refresh state to false,
      setInterval(() => {
        setRefreshState(false);
      }, 4000);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        dispatch.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  return (
    <>
      <TipFormModel
        title="Add Tip"
        showModal={isAddShow}
        toggleModal={() => {
          toggleAddModal();
          dispatch.reset();
        }}
        onSubmit={handleSubmit}
        state={state}
        isCreated={true}
      />
      <Stack spacing={5} padding={3}>
        <Stack
          style={{
            display: "flex",
            justifyContent: "center",
            alignSelf: "end",
          }}
        >
          <Button
            variant="outlined"
            size="large"
            startIcon={<AddIcon />}
            onClick={toggleAddModal}
          >
            Add
          </Button>
        </Stack>
        <Stack>
          <ListingTable
            columns={columns}
            controller={useCallback(
              async (data) => await getAllTipWithPagination(data),
              []
            )}
            onDetail={onDetail}
            deleteRecord={deleteTip}
            updateRecord={updateTip}
            deleteModelTitle="Do you want to delete this tip?"
            refreshState={refreshState}
          />
        </Stack>
      </Stack>
    </>
  );
}

export default TipListing;

const columns = [
  {
    field: "tipTitle",
    headerName: "Tip Title",
    flex: 1,
  },
  {
    field: "isPublished",
    headerName: "Published Status",
    minWidth: 150,
    flex: 1,
    valueGetter: (params) =>
      `${params.row.isPublished ? "Published" : "Not Published"}`,
  },
  {
    field: "publishedAt",
    headerName: "Publish Date",
    minWidth: 120,
    flex: 1,
    valueGetter: (params) =>
      `${moment(params.row.publishedAt).format("DD-MMM YYYY")}`,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    minWidth: 120,
    flex: 1,
    valueGetter: (params) =>
      `${moment(params.row.createdAt).format("DD-MMM YYYY")}`,
  },
  {
    field: "edit",
    headerName: "Edit",
    sortable: false,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    // flex: 1,
    disableClickEventBubbling: true,
    renderCell: () => {
      return <Button>Edit</Button>;
    },
  },
  {
    field: "delete",
    headerName: "Delete",
    sortable: false,
    minWidth: 140,
    // flex: 1,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return <Button color="error">delete</Button>;
    },
  },
];
