import moment from "moment";
import React, { useCallback, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Stack, Button } from "@mui/material";
import { ListingTable, LocationFormModel } from "./components";

// hooks
import useVisible from "../../../../hooks/useVisible";
import useResponse from "../../../../hooks/useResponse";

// services
import {
  createLocation,
  deleteLocation,
  updateLocation,
  getAllLocationWithPagination,
} from "../../../../services/locationService";
import * as toast from "../../../../Toast";

// utils
import trimObjectValues from "../../../../utils/trimObject";

function ListingLocation() {
  const [isAddShow, toggleAddModal] = useVisible(false);
  const [state, dispatch] = useResponse();
  const [refreshState, setRefreshState] = useState(false);

  const handleSubmit = async (locationData) => {
    const trimLocationData = trimObjectValues(locationData);
    dispatch.sendingRequst();
    try {
      const response = await createLocation(trimLocationData);
      const data = await response.data;
      toggleAddModal();
      toast.success(data.message);
      dispatch.successResponse(data.message);

      // set refresh state to true, to refresh listing
      setRefreshState(true);
      // set refresh state to false,
      setInterval(() => {
        setRefreshState(false);
      }, 4000);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        dispatch.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  return (
    <>
      <LocationFormModel
        title="Add Location"
        showModal={isAddShow}
        toggleModal={() => {
          toggleAddModal();
          dispatch.reset();
        }}
        onSubmit={handleSubmit}
        state={state}
      />
      <Stack spacing={5} padding={3}>
        <Stack
          style={{
            display: "flex",
            justifyContent: "center",
            alignSelf: "end",
          }}
        >
          <Button
            variant="outlined"
            size="large"
            startIcon={<AddIcon />}
            onClick={toggleAddModal}
          >
            Add
          </Button>
        </Stack>
        <Stack>
          <ListingTable
            columns={columns}
            // onEditClick={onEdit}
            controller={useCallback(
              async (data) => await getAllLocationWithPagination(data),
              []
            )}
            deleteRecord={deleteLocation}
            updateRecord={updateLocation}
            deleteModelTitle="Are you sure you want to delete it?"
            refreshState={refreshState}
          />
        </Stack>
      </Stack>
    </>
  );
}

export default ListingLocation;

const columns = [
  {
    field: "locationArea",
    headerName: "Location Name",
    width: 200,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    width: 200,
    valueGetter: (params) =>
      `${moment(params.row.createdAt).format("DD-MMM YYYY")}`,
  },
  {
    field: "edit",
    headerName: "Edit",
    headerAlign: "center",
    align: "center",
    sortable: false,
    minWidth: 140,
    disableClickEventBubbling: true,
    renderCell: () => {
      return <Button>Edit</Button>;
    },
  },
  {
    field: "delete",
    headerName: "Delete",
    sortable: false,
    minWidth: 140,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return <Button color="error">delete</Button>;
    },
  },
];
