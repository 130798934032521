// import moment from "moment-timezone";

export function convertDatetimeToPerth(date) {
  return new Date(date).toLocaleString("en-US", {
    timeZone: "Australia/Perth",
  });
  // return moment(date).tz("Australia/Perth");
}

export function convertDatetimeToEucla(date) {
  return new Date(date).toLocaleString("en-US", {
    timeZone: "Australia/Eucla",
  });
  // return moment(date).tz("Australia/Eucla");
}

export function convertDatetimeToAdelaide(date) {
  return new Date(date).toLocaleString("en-US", {
    timeZone: "Australia/Adelaide",
  });
  // return moment(date).tz("Australia/Adelaide");
}

export function convertDatetimeToSydney(date) {
  return new Date(date).toLocaleString("en-US", {
    timeZone: "Australia/Sydney",
  });
  // return moment(date).tz("Australia/Sydney");
}

export function convertDatetimeToLordHoweIsland(date) {
  return new Date(date).toLocaleString("en-US", {
    timeZone: "Australia/Lord_Howe",
  });
  // return moment(date).tz("Australia/Lord_Howe");
}

export function convertDateTimeTogivenTimezone(date, timezone) {
  let convertedTime;

  switch (timezone) {
    case "AWST":
      convertedTime = convertDatetimeToPerth(date);
      break;
    case "ACWST":
      convertedTime = convertDatetimeToEucla(date);
      break;
    case "ACST":
      convertedTime = convertDatetimeToAdelaide(date);
      break;
    case "AEST":
      convertedTime = convertDatetimeToSydney(date);
      break;
    case "LHST":
      convertedTime = convertDatetimeToLordHoweIsland(date);
      break;
    default:
      convertedTime = convertDatetimeToLordHoweIsland(date);
      break;
  }

  return convertedTime;
}

export function getTimezone(code) {
  let timezone;

  switch (code) {
    case "AWST":
      timezone = "Australia/Perth";
      break;
    case "ACWST":
      timezone = "Australia/Eucla";
      break;
    case "ACST":
      timezone = "Australia/Adelaide";
      break;
    case "AEST":
      timezone = "Australia/Sydney";
      break;
    case "LHST":
      timezone = "Australia/Lord_Howe";
      break;
    default:
      timezone = "Australia/Lord_Howe";
      break;
  }

  return timezone;
}

export function getTimezoneForlisting(code) {
  let timezone;

  switch (code) {
    case "AWST":
      timezone = "Perth (AWST)";
      break;
    case "ACWST":
      timezone = "Eucla (ACWST)";
      break;
    case "ACST":
      timezone = "Adelaide (ACST)";
      break;
    case "AEST":
      timezone = "Sydney (AEST)";
      break;
    case "LHST":
      timezone = "Lord Howe Island (LHST)";
      break;
    default:
      timezone = "Lord Howe Island (LHST)";
      break;
  }

  return timezone;
}
