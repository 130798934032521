import React from "react";
// components
import { ArticleForm } from "./component";
// hooks
import useResponse from "../../../../hooks/useResponse";
// services
import { createArticle } from "../../../../services/articleService";
import { imageUploadAPI } from "../../../../services/uploadService";
import * as toast from "../../../../Toast";
// utils
import trimObjectValues from "../../../../utils/trimObject";

function AddArticle({ onBack }) {
  const [state, dispatch] = useResponse();

  const handleSubmit = async (articleFormData) => {
    let articleDate = new Date(articleFormData.articleDate);
    articleFormData.articleDate = articleDate.toISOString();

    const { selectedImage } = articleFormData;

    dispatch.sendingRequst();
    try {
      const imageUploadRes = await imageUploadAPI(selectedImage);
      const imageRes = await imageUploadRes.data;

      const { articleTitle, articleData, status, articleDate } =
        trimObjectValues(articleFormData);

      const response = await createArticle({
        articleTitle,
        articleData: encodeURI(articleData),
        articleDate,
        status,
        articleImage: imageRes.file.filename,
        articleThumbnail: imageRes.thumbnail,
      });
      const data = await response.data;

      toast.success(data.message);
      dispatch.successResponse(data.message);
      // go back to listing page
      onBack();
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 409
      ) {
        dispatch.gotError(ex.response.data.message);
        toast.error(ex.response.data.message);
      }
    }
  };

  return (
    <ArticleForm
      title="Add Article"
      onSubmit={handleSubmit}
      state={state}
      onCancel={onBack}
    />
  );
}

export default AddArticle;
