import React, { useState } from "react";
import { useField } from "formik";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function FormInput({ label, type = "text", name }) {
  const [showPassword, setShowPassword] = useState(false);
  const [field, meta] = useField(name);

  return (
    <TextField
      fullWidth
      size="small"
      type={showPassword ? "text" : type}
      label={label}
      {...field}
      error={Boolean(meta.touched && meta.error)}
      helperText={meta.touched && meta.error}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setShowPassword((prev) => !prev)}>
              {showPassword ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default FormInput;
