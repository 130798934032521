import React from "react";
import moment from "moment";
import { useField } from "formik";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

function FormInputDate({
  label,
  name,
  onChange,
  // ...otherProps
}) {
  const [field, meta] = useField(name);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        inputFormat="dd/MM/yyyy"
        label={label}
        minDate={moment().toDate()}
        {...field}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            size="small"
            {...params}
            error={Boolean(meta.touched && meta.error)}
            helperText={meta.touched && meta.error}
          />
        )}
      />
    </LocalizationProvider>
  );
}

export default FormInputDate;
