import moment from "moment";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Stack, Box, Typography } from "@mui/material";
// components
import { Loader, ErrorMessage } from "../../../../components";
import { ShowHtmlContent } from "./component";

// services
import { getSingleArticle } from "../../../../services/articleService";
import http from "../../../../services/httpService";
// import resetButton from "../../../../assets/reset-button.png";

function PublicArticle() {
  const navigate = useNavigate();
  const params = useParams();
  const articleId = params.id;

  const onError = (ex) => {
    if (ex.response && ex.response.status >= 400 && ex.response.status <= 409) {
      navigate("/not-found");
    }
  };

  const { isError, isLoading, data, error } = useQuery(
    ["article-detail", articleId],
    () => getSingleArticle(articleId),
    { refetchOnWindowFocus: true, onError }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorMessage message={error.response.data.message} />;
  }

  const article = data?.data?.data["article"];

  return (
    <Stack
      spacing={5}
      // padding={3}
      style={{ overflowY: "scroll" }}
      // overflow="scroll"
      height="100vh"
      margin="0 auto"
    >
      <Stack maxWidth={1000} margin="0 auto" height="100vh">
        <img
          src={`${http.baseUrl}images/${article?.articleImage}`}
          width="100%"
          // width: "100%",
          // height: "32vh",
          // background:
          //   "url(https://sixthdayracing.jhavtechserver.com/images/logo-with-text.png)",

          alt={article.articleTitle}
        />
        <Stack
          padding={3}
          marginTop={-2}
          backgroundColor="white.main"
          style={{
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
          }}
        >
          <Typography variant="h5" fontWeight="600" color="textColor.main">
            {article.articleTitle}
          </Typography>
          <Typography
            variant="body1"
            color="smallTextColor.main"
            style={{ margin: "10px 0px" }}
            fontWeight="300"
          >
            {moment(article.articleDate).format("DD MMMM YYYY")}
          </Typography>
          <Typography
            variant="subtitle1"
            color="textColor.main"
            style={{ margin: "10px 0px" }}
            fontWeight="300"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <ShowHtmlContent content={article.articleData} />
            </Box>
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default PublicArticle;
