import http from "./httpService";

const apiEndpoint = http.apiUrl + "tip-race";

function tipRaceURl(id) {
  return `${apiEndpoint}/${id}`;
}

// // service is used to get all Tip Race
// export function getAllTipRace() {
//   return http.get(apiEndpoint);
// }

// // service is used to get details of single Tip Race
// export function getSingleTipRace(id) {
//   return http.get(tipRaceURl(id));
// }

function tipRaceByTipLocationIDURl(tipLocationId) {
  return `${apiEndpoint}/by-tip-location/${tipLocationId}`;
}

// service is used to get all Tip Races by tip location id
export function getAllTipRaceByTipLocationId(tipLocationId) {
  return http.get(tipRaceByTipLocationIDURl(tipLocationId));
}

// service is used to create Tip Race
export function createTipRace(data) {
  return http.post(apiEndpoint, data);
}

// service is used to update Tip Race
export function updateTipRace(id, data) {
  return http.put(tipRaceURl(id), data);
}

// service is used to delete Tip Race
export function deleteTipRace(id, data) {
  return http.delete(tipRaceURl(id), { data });
}
