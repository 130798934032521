import http from "./httpService";

const apiEndpoint = http.apiUrl + "race";

function raceEventURl(id) {
  return `${apiEndpoint}/${id}`;
}

const constructionURL = (API_URL_ROUTE, { page, limit }) => {
  const url = new URL(apiEndpoint + API_URL_ROUTE);

  url.searchParams.set("page", page + 1);
  url.searchParams.set("limit", limit);

  return url;
};

// service is used to get all races events
export function getAllRace() {
  return http.get(apiEndpoint);
}

// service is used to get all races events with pagination
export function getAllRaceWithPagination({ page = 0, limit = 10 }) {
  const url = constructionURL("/listing", { page, limit });
  return http.get(url);
}

// service is used to get details of single race event
export function getSingleRace(id) {
  return http.get(raceEventURl(id));
}

// service is used to create race event
export function createRace(data) {
  return http.post(apiEndpoint, data);
}

// service is used to update race event
export function updateRace(id, data) {
  return http.put(raceEventURl(id), data);
}

// service is used to delete race event
export function deleteRace(id) {
  return http.delete(raceEventURl(id));
}
