import React from "react";
import styles from "./logo.module.css";
import LogoWithText from "./../assets/logo-with-text.png";
// import Logo from "./../assets/logo.png";

function Logo() {
  return (
    <div className={styles.logoContainer}>
      <img src={LogoWithText} alt="make it fit logo" height="150" width="150" />
    </div>
  );
}

export default Logo;
