import React, { useCallback, useEffect, useState } from "react";
import { Stack } from "@mui/material";
// components
import { CustomPagination, StyledDataGrid } from "../../../components";

// hooks
import useQuery from "../../../../../hooks/useQuery";

import * as toast from "../../../../../Toast";

function ListingTable({ columns, controller, result = [], pages = 0 }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(result);
  const [totalPages, setTotalPages] = useState(pages);
  const [queryParams, setQueryParams] = useQuery();

  let page = queryParams.get("page") ? parseInt(queryParams.get("page")) : 1;
  page = page - 1;

  const fetchData = useCallback(
    async ({ page }) => {
      try {
        setLoading(true);
        const response = await controller({
          page: page,
          limit: 10,
        });
        const { data } = await response.data;
        setData(data.result);
        setTotalPages(data.total);
      } catch (ex) {
        toast.error(ex.response.statusText);
      }
      setLoading(false);
    },
    [controller]
  );

  useEffect(() => {
    fetchData({ page: page });
  }, [fetchData, page]);

  return (
    <>
      <StyledDataGrid
        rows={data}
        columns={columns}
        rowCount={totalPages}
        pageSize={10}
        rowsPerPageOptions={[10]}
        page={page}
        onPageChange={(page) => {
          setQueryParams({ page: page + 1 });
        }}
        disableColumnMenu
        disableColumnSelector
        disableColumnFilter
        paginationMode="server"
        // onFilterModelChange={(model) => console.log(model)}
        loading={loading}
        hideFooterSelectedRowCount={true}
        getRowClassName={(params) => {
          return params.row.checkInDone ? "awaiting-response" : "";
        }}
        autoHeight
        components={{
          Pagination: CustomPagination,
          // ColumnSortedAscendingIcon: MdArrowDropUp,
          // ColumnSortedDescendingIcon: MdArrowDropDown,
          NoRowsOverlay: () => (
            <Stack
              height="100%"
              alignItems="center"
              justifyContent="center"
              style={{ fontSize: "1.5rem" }}
            >
              No rows
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack
              height="100%"
              alignItems="center"
              justifyContent="center"
              style={{ fontSize: "1.5rem" }}
            >
              No result
            </Stack>
          ),
        }}
      />
    </>
  );
}

export default ListingTable;
