import { Box, Button, Stack } from "@mui/material";
import React from "react";
import * as Yup from "yup";
import {
  Form,
  FormInput,
  SubmitLoadingButton,
} from "../../../../../components/Forms";

// login validationSchema
const validationSchema = Yup.object().shape({
  locationArea: Yup.string()
    .required()
    .min(2)
    .max(50)
    .label("Location Name")
    .trim(),
});

const initialValue = {
  locationArea: "",
};

function LocationForm({
  onSubmit,
  initialValues = initialValue,
  state,
  toggleModal,
}) {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <FormInput label="Location Name" name="locationArea" type="text" />
        <Stack spacing={2} direction="row">
          <Button fullWidth variant="outlined" onClick={toggleModal}>
            CANCEL
          </Button>
          <SubmitLoadingButton
            buttonText="Save"
            type="submit"
            submitButton={true}
            loading={state.loading}
          />
        </Stack>
        {/* <SuccessAndErrorMessage error={state.error} success={state.message} /> */}
      </Box>
    </Form>
  );
}

export default LocationForm;
