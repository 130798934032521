import moment from "moment";
import React, { useCallback } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Stack, Button } from "@mui/material";
import { ListingTable } from "./component";

// services
import {
  deleteArticle,
  getAllArticleWithPagination,
} from "../../../../services/articleService.js";

function ArticleListing({ onAdd, onEdit, onDetail }) {
  return (
    <Stack spacing={5} padding={3}>
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          alignSelf: "end",
        }}
      >
        <Button
          variant="outlined"
          size="large"
          startIcon={<AddIcon />}
          onClick={onAdd}
        >
          Add
        </Button>
      </Stack>
      <Stack>
        <ListingTable
          columns={columns}
          onDetail={onDetail}
          onEditClick={onEdit}
          controller={useCallback(
            async (data) => await getAllArticleWithPagination(data),
            []
          )}
          deleteRecord={deleteArticle}
          deleteModelTitle="Do you want to delete this article?"
        />
      </Stack>
    </Stack>
  );
}

export default ArticleListing;

const columns = [
  {
    field: "articleTitle",
    headerName: "Article Title",
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    width: 80,
    valueGetter: (params) => `${params.row.status ? "Active" : "Inactive"}`,
  },
  {
    field: "articleDate",
    headerName: "Article Date",
    // flex: 1,
    width: 110,
    valueGetter: (params) =>
      `${moment(params.row.articleDate).format("DD-MMM YYYY")}`,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    width: 110,
    valueGetter: (params) =>
      `${moment(params.row.createdAt).format("DD-MMM YYYY")}`,
  },
  {
    field: "edit",
    headerName: "Edit",
    sortable: false,
    minWidth: 110,
    headerAlign: "center",
    align: "center",
    // flex: 1,
    disableClickEventBubbling: true,
    renderCell: () => {
      return <Button>Edit</Button>;
    },
  },
  {
    field: "delete",
    headerName: "Delete",
    sortable: false,
    minWidth: 140,
    // flex: 1,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return <Button color="error">delete</Button>;
    },
  },
];
