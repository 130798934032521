import http from "./httpService";

const apiEndpoint = http.apiUrl + "upload";

// API is used to image upload
export function imageUploadAPI(image) {
  const form = new FormData();
  form.append("image", image);

  return http.post(apiEndpoint, form);
}
