import React from "react";
import * as Yup from "yup";
import { useQuery } from "react-query";
import { Box, Button, Stack } from "@mui/material";

// component
import {
  Form,
  LocationDropDown,
  SubmitLoadingButton,
} from "../../../../../components/Forms";
import { Loader, ErrorMessage } from "../../../../../components";

// services
import { getAllLocation } from "../../../../../services/locationService";

// race event validationSchema
const validationSchema = Yup.object().shape({
  locationId: Yup.string().required().min(1).label("Location Name").trim(),
});

const filterLocationList = (locationList = [], locationIdList = []) => {
  return locationList.filter(
    (location) => !locationIdList.includes(location.id)
  );
};

const initialValue = {
  locationId: "",
};

function TipLocationForm({
  onSubmit,
  state,
  onCancel,
  locationIdList = [],
  initialValues = initialValue,
}) {
  const { isError, isLoading, data, error } = useQuery(
    ["locations"],
    () => getAllLocation(),
    { refetchOnWindowFocus: true }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorMessage message={error.response.data.message} />;
  }

  const locationList = data.data.data["locations"];

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <LocationDropDown
          label="Location Name"
          name="locationId"
          locationList={filterLocationList(locationList, locationIdList)}
        />
        <Stack spacing={2} direction="row">
          <Button fullWidth variant="outlined" onClick={onCancel}>
            CANCEL
          </Button>
          <SubmitLoadingButton
            buttonText="submit"
            type="submit"
            submitButton={true}
            loading={state.loading}
          />
        </Stack>
        {/* <SuccessAndErrorMessage error={state.error} success={state.message} /> */}
      </Box>
    </Form>
  );
}

export default TipLocationForm;
