import { useQuery } from "react-query";

// components
import { ConfigWrapper } from "./components";
import { Loader, ErrorMessage } from "../../../../components";
import { ShowHtmlContent } from "../Article/component";

// services
import { getTermsConditions } from "../../../../services/configService";

function TermsConditions() {
  const { isError, isLoading, data, error } = useQuery(
    ["terms-conditions"],
    () => getTermsConditions(),
    { refetchOnWindowFocus: true }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorMessage message={error.response.data.message} />;
  }

  const { termsConditionsDetail } = data?.data?.data["termsConditions"];

  return (
    <ConfigWrapper title="Terms & Conditions">
      <ShowHtmlContent content={termsConditionsDetail} />
    </ConfigWrapper>
  );
}

export default TermsConditions;
