import moment from "moment-timezone";
import React, { useCallback } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Stack, Button } from "@mui/material";
import { ListingTable } from "./component";

// services
import {
  deleteRace,
  getAllRaceWithPagination,
} from "../../../../services/raceEventService";
import {
  convertDateTimeTogivenTimezone,
  getTimezoneForlisting,
} from "../../utils/timezone";
function RaceListing({ onAdd, onEdit }) {
  return (
    <Stack spacing={5} padding={3}>
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          alignSelf: "end",
        }}
      >
        <Button
          variant="outlined"
          size="large"
          startIcon={<AddIcon />}
          onClick={onAdd}
        >
          Add
        </Button>
      </Stack>
      <Stack>
        <ListingTable
          columns={columns}
          onEditClick={onEdit}
          controller={useCallback(
            async (data) => await getAllRaceWithPagination(data),
            []
          )}
          deleteRecord={deleteRace}
          deleteModelTitle="Do you want to delete this race?"
        />
      </Stack>
    </Stack>
  );
}

export default RaceListing;

const columns = [
  {
    field: "raceName",
    headerName: "Race Name",
    flex: 1,
  },
  {
    field: "raceDate",
    headerName: "Race Date & Time",
    minWidth: 300,
    flex: 1,
    valueGetter: (params) =>
      `${moment(
        convertDateTimeTogivenTimezone(params.row.raceDate, params.row.timezone)
      )
        // .tz(getTimezone(params.row.timezone))
        .format("DD-MMM YYYY h:mm a")} ${getTimezoneForlisting(
        params.row.timezone
      )}`,
  },
  {
    field: "locationName",
    headerName: "Location",
    flex: 1,
  },
  //   {
  //     field: "locationAddress",
  //     headerName: "Address",
  //     // flex: 1,
  //   },
  {
    field: "createdAt",
    headerName: "Created At",
    minWidth: 100,
    flex: 1,
    valueGetter: (params) =>
      `${moment(params.row.createdAt).format("DD-MMM YYYY")}`,
  },
  {
    field: "edit",
    headerName: "Edit",
    sortable: false,
    minWidth: 90,
    headerAlign: "center",
    align: "center",
    // flex: 1,
    disableClickEventBubbling: true,
    renderCell: () => {
      return <Button>Edit</Button>;
    },
  },
  {
    field: "delete",
    headerName: "Delete",
    sortable: false,
    minWidth: 100,
    // flex: 1,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return <Button color="error">delete</Button>;
    },
  },
];
